.landing-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #9CA3AF;
    margin-bottom: 0.75rem;
}

.animate-text
{
    background-image: linear-gradient(
            -225deg,
            #ffffff 0%,
            #0095ff 50%,
            #2b48e3 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}