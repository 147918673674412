
header{
    position: -webkit-sticky; /* first two lines make navbar stay in same position while scrolling across different browsers */
    position: sticky;
    top: 0; /* stick to top of viewport */
    z-index: 10; /* for stacking navbar over Landing component */
}

footer{
    position: -webkit-sticky; /* first two lines make navbar stay in same position while scrolling across different browsers */
    position: sticky;
    bottom: 0; /* stick to top of viewport */
    z-index: 10; /* for stacking navbar over Landing component */
}

/* mobile first approach, organize flex items by col on mobile, then by row if on larger screen */
.flex-navbar{
    display: flex; /* use flexbox for responsive navbar */
    flex-wrap: nowrap;
    padding: 1.25rem;
    flex-direction: column;
    align-items: center;
}


/* mobile first approach, organize flex items by row if on larger screen */
@media (min-width: 768px) {
    .flex-navbar {
        flex-direction: row;
    }
}

.navbar-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: white;
    margin-bottom: 0.75rem;
}

@media (min-width: 768px) {
    .navbar-text {
        margin-bottom: 0; /* Override margin for larger screens, equivalent to md:mb-0 */
    }
}

.flex-nav-items {
    font-size: 1.25rem; /* Equivalent to md:text-xl in Tailwind CSS */
    margin-right: auto; /* Equivalent to md:mr-auto in Tailwind CSS */
    margin-left: 1rem; /* Equivalent to md:ml-4 in Tailwind CSS */
    padding-top: 0.25rem; /* Equivalent to md:py-1 in Tailwind CSS */
    padding-left: 1rem; /* Equivalent to md:pl-4 in Tailwind CSS */
    margin-right: 0.75rem; /* Equivalent to mr-3 in Tailwind CSS */
    display: flex;
    flex-wrap: nowrap;
    font-size: 0.92rem; /* Equivalent to text-base in Tailwind CSS */
    align-items: center;
}

@media (min-width: 768px) {
    .flex-nav-items {
        font-size: 1.5rem; /* Adjust font size for larger screens */
        align-items: end;
    }
}

.page {
    position: absolute;
    width: 100%;
    height: 100%;
}

.slide-enter {
    transform: translateX(100%);
    opacity: 0;
}
.slide-enter-active {
    transform: translateX(0%);
    opacity: 1;
    transition: transform 500ms;
}
.slide-exit {
    transform: translateX(0%);
    opacity: 1;
}
.slide-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 500ms;
}