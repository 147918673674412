.projects-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #9CA3AF;
    margin-bottom: 0.75rem;
}

.projects-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #6B7280;
    margin-bottom: 0.75rem;
}

@keyframes expand {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes shrink {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.5);
    }
}

.animate-expand {
    animation: expand 0.3s ease-out forwards;
}

.animate-shrink {
    animation: shrink 0.3s ease-out forwards;
}
